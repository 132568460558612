<template>
  <div id="page-user-list">
    <div class="vx-row">
    </div>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <!-- COMPUTED STORE CHECK USE BANK  DO NOT DELETE BY LILSHARK-->
          <div class="vx-card__title">
            <h4>เลือกวันที่</h4>
            <br>
          </div>
          <flat-pickr v-model="date" />
          <p class="mt-4">Selected Date: {{ date }}</p>
        </div>
      </div>
      <vs-table stripe max-items="20" pagination :data="listdata" search>
        <template slot="thead">
          <vs-th>บัญชี</vs-th>
          <vs-th>วัน/เวลา</vs-th>
          <vs-th>ยอดเงิน</vs-th>
          <vs-th>ข้อมูล</vs-th>
          <vs-th>สถานะ</vs-th>
          <vs-th></vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.log_trans_for">
              {{tr.log_trans_for}}
            </vs-td>
            <vs-td :data="tr.log_trans_datetime">
              {{moment(tr.log_trans_datetime).format('YYYY-MM-DD HH:mm:ss')  }}
            </vs-td>
            <vs-td :data="tr.log_trans_amount">
              {{currency (tr.log_trans_amount)}}
            </vs-td>
            <vs-td :data="tr.log_trans_to">
              {{tr.log_trans_to}}
            </vs-td>
            <vs-td :data="tr.log_compare_status">
              <span v-if="tr.log_compare_status == true" class="text-success">ปกติ</span>
              <span v-else class="text-danger">ผิดพลาด</span>
            </vs-td>
            <!-- Status END -->
            <!-- button -->
            <vs-td>
              <vs-button
                @click="input_popup(tr.log_trans_for, tr.log_trans_datetime, tr.log_trans_amount, tr.log_trans_from, tr.log_trans_to, tr.log_compare_info, tr.log_compare_status, tr.log_compare_datetime)"
                type="border" size="small" icon-pack="feather" icon="icon-list" class="mr-2" >เพิ่มเติม</vs-button>
            </vs-td>
            <!-- button end-->
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <!-- popup_info -->
    <vs-popup classContent="popup-example" title="รายละเอียด" :active.sync="popup_info">
            <vs-table stripe  :data="info">
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>ธนาคาร :</vs-th>  
            <vs-td :data="tr.bank">
              {{tr.bank}}
            </vs-td>
          </vs-tr>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>โอนเวลา :</vs-th>  
            <vs-td :data="tr.time">
              {{moment(tr.time).format('YYYY-MM-DD HH:mm:ss')}}
            </vs-td>
          </vs-tr>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>ยอดเงิน :</vs-th>  
            <vs-td :data="tr.amount">
              {{currency (tr.amount)}}
            </vs-td>
          </vs-tr>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>โอนจากบัญชี :</vs-th>  
            <vs-td :data="tr.bankfrom">
              {{tr.bankfrom}}
            </vs-td>
          </vs-tr>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>โอนไปบัญชี :</vs-th>  
            <vs-td :data="tr.bnakto">
              {{tr.bnakto}}
            </vs-td>
          </vs-tr>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>ข้อมูลเพิ่มเติม :</vs-th>  
            <vs-td :data="tr.deteil">
              {{tr.deteil}}
            </vs-td>
          </vs-tr>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>สถานะ :</vs-th>  
            <vs-td :data="tr.status">
              <span v-if="tr.status == true" class="text-success">ปกติ</span>
              <span v-else class="text-danger">ผิดพลาด</span>
            </vs-td>
          </vs-tr>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-th>เวลาตรวจสอบ :</vs-th>  
            <vs-td :data="tr.timetocheck">
              {{moment(tr.timetocheck).format('YYYY-MM-DD HH:mm:ss')}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import axios from '../../axios'
import moment from 'moment'

export default {
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      listdata: [],
      popup_info: false,
      info: [
        {
          bank: '',
          time: '',
          amount: '',
          bankfrom: '',
          bnakto: '',
          deteil: '',
          status: '',
          timetocheck: ''
        }
      ]
    }
  },
  async mounted () {
    await this.selectdate()
  },
  watch: {
    date (newValue) {
      this.date = newValue
      this.selectdate()
    }
  },
  methods: {
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String((amount))
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
    },
    async selectdate () {
      await axios
        .get(
          `bankcompare/listerror/${this.date}`
        )
        .then(response => (this.listdata = response.data))
    },
    input_popup (trans_for, trans_datetime, trans_amount, trans_from, trans_to, compare_info, compare_status,
      compare_datetime) {
      this.info[0].bank = trans_for
      this.info[0].time = trans_datetime
      this.info[0].amount = trans_amount
      this.info[0].bankfrom = trans_from
      this.info[0].bnakto = trans_to
      this.info[0].deteil = compare_info
      this.info[0].status = compare_status
      this.info[0].timetocheck = compare_datetime
      this.popup_info = true

    }
  }
}

</script>
